import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = (props) => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      viewBox='0 0 256 256'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      {...otherProps}
    >
      <path
        d='M215.4,92.9A8,8,0,0,0,208,88H48a8,8,0,0,0-7.4,4.9,8.4,8.4,0,0,0,1.7,8.8l80,80a8.2,8.2,0,0,0,11.4,0l80-80A8.4,8.4,0,0,0,215.4,92.9Z' />
    </svg>
  );
};

ArrowDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowDown.defaultProps = {
  color: 'currentColor',
  size: '24',
};

export default ArrowDown;
