import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 6px;
  background: transparent;

  & svg {
    vertical-align: middle;
  }
`;

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="2.5"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

function MenuToggle({ open, toggle }) {
  return (
    <Button onClick={toggle}>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <Path
          animate={open ? 'open' : 'closed'}
          initial={{ d: 'M 2 4.5 L 22 4.5' }}
          variants={{
            closed: { d: 'M 2 4.5 L 22 4.5' },
            open: { d: 'M 3 19.5 L 19 4.5' },
          }}
        />
        <Path
          d="M 2 12 L 22 12"
          animate={open ? 'open' : 'closed'}
          initial={{ opacity: 1 }}
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          animate={open ? 'open' : 'closed'}
          initial={{ d: 'M 2 19.5 L 22 19.5' }}
          variants={{
            closed: { d: 'M 2 19.5 L 22 19.5' },
            open: { d: 'M 3 4.5 L 19 19.5' },
          }}
        />
      </svg>
    </Button>
  );
}

export default MenuToggle;
