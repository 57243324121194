import styled from 'styled-components';

import { mq } from '@utils/responsive';
import colors from '@utils/colors';

export const Blob = styled.a`
  position: relative;
  margin-right: 8px;
  color: ${colors.B500};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;

  & svg {
    z-index: 1;
  }

  ${mq.gtsm`
    margin-right: 16px;
  `}
`;

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  color: ${colors.B100};

  ${Blob}:hover & {
    color: ${colors.B200};
  }
`;
