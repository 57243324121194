import React from 'react';
import styled, { css } from 'styled-components';

import { mq, useBreakpoint } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';

const Wrapper = styled.div`
  max-width: 1280px;
  padding: 0 ${(props) => props.noPadding ? 0 : H_PADDING_MOBILE};

  ${(props) => props.centered && css`
    margin: 0 auto;
  `}

  ${(props) => props.hideOverflow && css`
    overflow-x: hidden;
  `}

  ${mq.gtmd`
    width: ${(props) => `${Math.min(props.columnSize, 12) / 12 * 100}%`};
  `}

  ${mq.gtml`
    padding: 0;
  `}
`;

function Container({ centered, children, column, columns, hideOverflow, noPadding }) {
  const responsiveSize = useBreakpoint(columns);
  const columnSize = column || responsiveSize || 12;

  return (
    <Wrapper centered={centered} columnSize={columnSize} hideOverflow={hideOverflow} noPadding={noPadding}>
      {children}
    </Wrapper>
  );
}

export default Container;
