import React from 'react';
import PropTypes from 'prop-types';
import { Link as UnstyledLink } from 'gatsby-plugin-react-intl';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import useBannerContext from '@utils/useBanner';
import GlobalSearch from '@components/GlobalSearch';
import LanguageSelector from '@components/LanguageSelector';
import Wordmark from '@components/Wordmark';
import NavItems from './NavItems';

const Box = styled.div`
  display: flex;
  justify-content: center;

  &:first-child > * {
    margin-right: auto;
  }

  &:last-child > * {
    margin-left: auto;
  }
`;

const BoxInner = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 24px;
    vertical-align: middle;
  }
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(1280px + 40px);
  padding: 0 4px;
  margin: 0 auto;

  ${mq.gtml`
    padding: 0;
  `}
`;

const Link = styled(UnstyledLink)`
  & > svg {
    vertical-align: middle;
  }
`;

const SearchWrapper = styled.div`
  padding: 0 16px 0 24px;
`;

const Wrapper = styled(motion.nav)`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9998;
  background-color: transparent;
  transition: background-color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

  ${(props) =>
    props.hasScrolled &&
    css`
      background-color: ${colors.N0};
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    `}

  ${(props) =>
    props.hasBanner &&
    css`
      top: ${props.bannerHeight}px;
    `
  }
`;

const variants = {
  collapsed: {
    height: 80,
  },
  expanded: {
    height: 186,
  },
};

function DesktopNav({ banner, hasScrolled, navItems }) {
  const { height, viewed } = useBannerContext();

  return (
    <Wrapper
      bannerHeight={height}
      hasBanner={get(banner, 'visible') && !viewed}
      hasScrolled={hasScrolled}
      initial={{ height: 186 }}
      animate={hasScrolled ? 'collapsed' : 'expanded'}
      variants={variants}
      transition={{ ease: 'easeInOut', duration: 0.25 }}
    >
      <Inner>
        <Box>
          <NavItems items={navItems.filter((item) => item.type === 'primary')} primary />
        </Box>
        <Box>
          <Link to="/" aria-label="Miss Hall's School">
            <Wordmark collapsed={hasScrolled} />
          </Link>
        </Box>
        <Box>
          <BoxInner>
            <NavItems items={navItems.filter((item) => item.type === 'secondary')} />
            <LanguageSelector />
            <SearchWrapper>
              <GlobalSearch bannerHeight={height} hasBanner={get(banner, 'visible') && !viewed} />
            </SearchWrapper>
          </BoxInner>
        </Box>
      </Inner>
    </Wrapper>
  );
}

DesktopNav.propTypes = {
  hasScrolled: PropTypes.bool.isRequired,
  navItems: PropTypes.array.isRequired,
};

export default DesktopNav;
