import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import colors from '@utils/colors';

const Inner = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;

  ${(props) =>
    props.hasBanner &&
    css`
      top: ${80 + props.bannerHeight}px;
    `}
`;

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9997;
  background-color: ${colors.N0};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};
`;

const variants = {
  inner: {
    open: {
      transition: { staggerChildren: 0.04, delayChildren: 0.25 },
    },
    closed: {
      transition: { staggerChildren: 0.02, staggerDirection: -1 },
    },
  },
  outer: {
    collapsed: {
      height: 0,
      transition: {
        delay: 0.25,
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.3,
      },
    },
    expanded: {
      height: typeof window !== 'undefined' ? window.innerHeight : 0,
      transition: {
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.3,
      },
    },
  },
};

function Menu({ bannerHeight, children, hasBanner, isOpen }) {
  return (
    <Wrapper
      animate={isOpen ? 'expanded' : 'collapsed'}
      isOpen={isOpen}
      variants={variants.outer}
    >
      <Inner
        animate={isOpen ? 'open' : 'closed'}
        bannerHeight={bannerHeight}
        hasBanner={hasBanner}
        variants={variants.inner}
      >
        {children}
      </Inner>
    </Wrapper>
  );
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Menu;
