import React from 'react';
import { Link as UnstyledLink } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import colors from '@utils/colors';
import { H_PADDING_MOBILE } from '@utils/space';
import { Heading } from '@components/type';

const ITEM_HEIGHT = 48;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: ${ITEM_HEIGHT}px;
`;

const Link = styled(UnstyledLink)`
  display: block;
  z-index: 2;
`;

const Wrapper = styled(motion.div)`
  position: relative;
  padding: 0 ${H_PADDING_MOBILE};
  background-color: ${colors.B100};
`;

function SubMenu({ closeMenu, isOpen, items }) {
  return (
    <Wrapper
      initial="collapsed"
      animate="open"
      exit="collapsed"
      isOpen={isOpen}
      variants={{
        open: { opacity: 1, height: ITEM_HEIGHT * items.length, zIndex: 1 },
        collapsed: { opacity: 0, height: 0, zIndex: -1 },
      }}
      transition={{
        type: 'tween',
        ease: 'easeInOut',
        duration: 0.2,
      }}
    >
      {items.map((item) => (
        <Link key={item.title} to={item.slug.current} onClick={closeMenu}>
          <Item>
            <Heading level={500}>{item.title}</Heading>
          </Item>
        </Link>
      ))}
    </Wrapper>
  );
}

SubMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default SubMenu;
