import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import useBannerContext from '@utils/useBanner';
import Menu from './Menu';
import MenuFooter from './MenuFooter';
import MenuItem from './MenuItem';
import NavBar from './NavBar';

function MobileNav({ banner, hasScrolled, navItems }) {
  const { height, viewed } = useBannerContext();

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <NavBar
        bannerHeight={height}
        closeMenu={closeMenu}
        hasBanner={get(banner, 'visible') && !viewed}
        hasScrolled={hasScrolled}
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
      />
      <Menu bannerHeight={height} hasBanner={get(banner, 'visible') && !viewed} isOpen={isOpen}>
        {navItems.map((item, idx) => (
          <MenuItem key={idx} closeMenu={closeMenu} menuIsOpen={isOpen} {...item} />
        ))}
        <MenuFooter closeMenu={closeMenu} />
      </Menu>
    </div>
  );
}

MobileNav.propTypes = {
  hasScrolled: PropTypes.bool.isRequired,
  navItems: PropTypes.array.isRequired,
};

export default MobileNav;
