import React from 'react';
import DefaultImage from 'gatsby-image';
import useDimensions from 'react-use-dimensions';
import styled, { css, keyframes } from 'styled-components';
import { browserVersion, browserName } from 'react-device-detect';
import Link from '@components/Link';

import {
  RippedOne,
  RippedTwo,
  RippedThree,
  RippedFour,
  RippedFive,
} from '@components/vector/clipped';

const edges = [RippedOne, RippedTwo, RippedThree, RippedFour, RippedFive];
const edgeIds = [
  '#rippedEdgeOne',
  '#rippedEdgeTwo',
  '#rippedEdgeThree',
  '#rippedEdgeFour',
  '#rippedEdgeFive',
];

export const AbsoluteImage = styled(DefaultImage)`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  clip-path: inset(0 0 ${(props) => `${props.offset}px`} 0);
`;

const ClippedImage = styled(AbsoluteImage)`
  position: relative;
`;

const ClipWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Clip = styled.div`
  width: 100%;
  height: ${props => props.height ? `${props.height}px` : 0};
  clip-path: url(${(props) => props.edgeUrl});
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  animation: 500ms linear ${fadeIn};

  ${(props) => getRatioStyles(props.ratio)};

  & > svg {
    position: absolute;
    width: 100%;
    opacity: 0;
    pointer-events: none;
  }
`;

function getRatioStyles(ratio) {
  return css`
    padding-bottom: ${ratio * 100}%;
  `;
}

function Image({ className, edge, isHidden, ratio = 1, theme, ...rest }) {
  const [edgeRef, { height: edgeHeight }] = useDimensions({ liveMeasure: false });

  const EdgeImage = edges[edge];
  return (
    <Wrapper className={className} ratio={ratio}>
      {!isNaN(edge) && `${browserName} ${browserVersion}`.indexOf('Safari 14') === -1 && (
        <>
          <ClipWrapper height={edgeHeight}>
            <Clip edgeUrl={edgeIds[edge]} height={edgeHeight}>
              <ClippedImage fadeIn={false} {...rest} />
            </Clip>
          </ClipWrapper>
          <EdgeImage ref={edgeRef} />
        </>
      )}
      {(rest.fluid || rest.fixed) && (
        rest.link || rest.route
          ? <Link to={rest.route?.slug?.current || rest.link} title={rest.route?.title || rest.altText}>
            <AbsoluteImage fadeIn={false} offset={edgeHeight - 1 || 0} {...rest} />
          </Link>
          : <AbsoluteImage fadeIn={false} offset={edgeHeight - 1 || 0} {...rest} alt={rest.altText || "Miss Hall’s School"}/>
      )}
    </Wrapper>
  );
}

export default Image;
