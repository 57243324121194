import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';

const Body = styled.p`
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  line-height: 120%;
`;

const Items = styled.div`
  padding: 16px 32px;

  ${(props) => props.isHidden && css`
    opacity: 0;
    pointer-events: none;
  `}
`;

const Item = styled(Link)`
  display: block;
  padding: 16px 0;
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 18px;
  font-weight: ${weights.BOLD};
  line-height: 120%;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${(props) => getThemeStyles(props.colorTheme).background};
  }
`;

const MainItem = styled(Link)`
  display: block;
  padding: 32px;
  font-family: ${props => fonts(props.theme.locale).SANS};
  border-bottom: 3px solid ${(props) => getThemeStyles(props.colorTheme).background};

  ${(props) => props.isHidden && css`
    opacity: 0;
    pointer-events: none;
  `}
`;

const Title = styled.p`
  margin-bottom: 8px;
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  font-size: 24px;
  font-weight: ${weights.BOLD};
  line-height: 120%;
`;

function NavSection({ childRoutes, description, isHidden, route, title, colorTheme }) {
  const items = (childRoutes || []).filter((item) => item.title);

  return (
    <>
      <MainItem isHidden={isHidden} colorTheme={colorTheme} to={get(route, 'slug.current')}>
        <Title colorTheme={colorTheme}>{title}</Title>
        <Body>{description}</Body>
      </MainItem>
      {items.length > 0 && (
        <Items isHidden={isHidden}>
          {items.map((item, idx) => (
            <Item key={idx} to={get(item, 'slug.current')} colorTheme={colorTheme}>
              {item.title}
            </Item>
          ))}
        </Items>
      )}
    </>
  );
}

NavSection.propTypes = {
  childRoutes: PropTypes.array,
  colorTheme: PropTypes.oneOf(['blue', 'green', 'orange', 'purple', 'yellow']),
};

export default NavSection;

function getThemeStyles(colorTheme) {
  switch (colorTheme) {
    case 'blue':
      return {
        background: colors.B100,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        text: colors.P500,
      };
    default:
      return {
        background: colors.B100,
        text: colors.B500,
      };
  }
}
