import React from 'react';
import PropTypes from 'prop-types';

import colors from '@utils/colors';
import { Facebook, Instagram, Twitter, Youtube } from '@components/Icon';
import { BlobOne, BlobTwo, BlobThree, BlobFour } from '@components/vector';

import * as styled from './styles';

function SocialLink({ type, url }) {
  const getBlob = (type) => {
    switch (type) {
      case 'facebook':
        return <BlobOne color={colors.B100} />;
      case 'twitter':
        return <BlobTwo color={colors.B100} />;
      case 'instagram':
        return <BlobThree color={colors.B100} />;
      case 'youtube':
        return <BlobFour color={colors.B100} />;
      // no default
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'facebook':
        return <Facebook size="16" />;
      case 'twitter':
        return <Twitter size="16" />;
      case 'instagram':
        return <Instagram size="16" />;
      case 'youtube':
        return <Youtube size="16" />;
      // no default
    }
  };

  return (
    <styled.Blob href={url} target="_blank" rel="noopener noreferrer" aria-label={type}>
      <styled.Backdrop>
        {getBlob(type)}
      </styled.Backdrop>
      {getIcon(type)}
    </styled.Blob>
  );
}

SocialLink.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SocialLink;
