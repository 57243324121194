import React from 'react';
import styled from 'styled-components';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-react-intl';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { ChevronDown } from '@components/Icon';

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  margin-bottom: 24px;
  border: 2px solid ${colors.B100};
  border-radius: 8px;
  overflow: hidden;

  & select {
    display: block;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    width: calc(100% - 4px);
    padding: 0 12px;
    appearance: none;
    border: 0;
    color: ${colors.B500};
    font-size: 16px;
    font-weight: ${weights.BOLD};
  }

  & svg {
    position: absolute;
    right: 12px;
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  color: ${colors.B500};
  font-family: ${(props) => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  text-transform: uppercase;
`;

const languageName = {
  en: 'English',
  vi: 'Tiếng Việt',
  'zh-Hans': '简体中文',
};

function LanguageSelector({ onSelect }) {
  if (!process.env.GATSBY_SHOW_LANGUAGE_SELECTOR) {
    return null;
  }

  const activeLanguages = process.env.GATSBY_SHOW_LANGUAGE_SELECTOR.split(',');

  return (
    <Wrapper>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <SelectWrapper>
            <select
              onChange={(evt) => {
                changeLocale(evt.target.value);
                onSelect();
              }}
            >
              {languages
                .filter((language) => activeLanguages.includes(language))
                .map((language) => (
                  <option key={language} value={language}>
                    {languageName[language]}
                  </option>
                ))}
            </select>
            <ChevronDown size={12} />
          </SelectWrapper>
        )}
      </IntlContextConsumer>
    </Wrapper>
  );
}

export default LanguageSelector;
