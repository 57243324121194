/* global $crisp */
import React, { Component } from 'react';
import styled from 'styled-components';

import { mq } from '@utils/responsive';
import DesktopNav from './desktop';
import MobileNav from './mobile';

export const NavSpacer = styled.div`
  height: 76px;

  ${mq.gtmd`
    height: 186px;
  `}
`;

const Wrapper = styled.header`
  & > div:first-child {
    display: block;
  }

  & > div:last-child {
    display: none;
  }

  @media (min-width: 960px) {
    & > div:first-child {
      display: none;
    }

    & > div:last-child {
      display: block;
    }
  }
`;

class Navigation extends Component {
  constructor() {
    super();

    this.scrollTicking = false;
  }

  state = {
    hasScrolled: false,
  };

  componentDidMount() {
    $crisp.push(['do', 'chat:hide']);
    this.update();
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (!this.scrollTicking) {
      this.scrollTicking = true;
      requestAnimationFrame(this.update);
    }
  };

  update = () => {
    if (typeof window !== 'undefined') {
      if (window.pageYOffset > 0) {
        this.setState({ hasScrolled: true });
      } else {
        this.setState({ hasScrolled: false });
      }
    }

    this.scrollTicking = false;
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { banner, navItems } = this.props;
    const { hasScrolled } = this.state;

    return (
      <Wrapper>
        <div>
          <MobileNav
            banner={banner}
            navItems={navItems}
            hasScrolled={hasScrolled}
          />
        </div>
        <div>
          <DesktopNav
            banner={banner}
            navItems={navItems}
            hasScrolled={hasScrolled}
          />
        </div>
      </Wrapper>
    );
  }
}

export default Navigation;
