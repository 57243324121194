import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-react-intl';
import useOnClickOutside from 'use-onclickoutside';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { ChevronDown } from '@components/Icon';

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  padding: 2px;
  background-color: ${colors.N0};
  border: 2px solid ${colors.B100};
  border-radius: 8px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(4px);
  transition: opacity 100ms linear, transform 100ms ease-in;

  ${(props) =>
    props.isOpen &&
    css`
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    `}
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  width: 72px;
  padding: 0 12px;
  background-color: ${colors.N0};
  border: 2px solid ${colors.B100};
  border-radius: 8px;

  & > svg {
    position: absolute;
    right: 12px;
  }
`;

const Option = styled.div`
  padding: 4px 10px;
  font-size: 14px;
  text-transform: none;
  border-radius: 6px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: ${colors.B100};
  }
`;

const Wrapper = styled.div`
  margin-left: 12px;
  color: ${colors.B500};
  font-family: ${(props) => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  text-transform: uppercase;
`;

const languageName = {
  en: 'English',
  vi: 'Tiếng Việt',
  'zh-Hans': '简体中文',
};

const languageAbbreviation = {
  en: 'EN',
  vi: 'VI',
  'zh-Hans': 'ZH',
};

function LanguageSelector() {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useOnClickOutside(ref, handleClose);

  if (!process.env.GATSBY_SHOW_LANGUAGE_SELECTOR) {
    return null;
  }

  const activeLanguages = process.env.GATSBY_SHOW_LANGUAGE_SELECTOR.split(',');

  return (
    <Wrapper>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <DropdownWrapper onClick={handleOpen} ref={ref}>
            {languageAbbreviation[currentLocale]}
            <Dropdown isOpen={isOpen}>
              {languages
                .filter((language) => activeLanguages.includes(language))
                .map((language) => (
                  <Option key={language} onClick={() => changeLocale(language)}>
                    {languageName[language]}
                  </Option>
                ))}
            </Dropdown>
            <ChevronDown size={12} />
          </DropdownWrapper>
        )}
      </IntlContextConsumer>
    </Wrapper>
  );
}

export default LanguageSelector;
