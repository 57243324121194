import React, { useRef, useState } from 'react';
import { navigate } from 'gatsby-plugin-react-intl';
import styled, { css } from 'styled-components';
import get from 'lodash/get';
import qs from 'query-string';
import { useIntl } from '@utils/localize';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import Container from '@components/Container';
import { Close, Search } from '@components/Icon';
import { RoughOne } from '@components/vector';

const Form = styled.form`
  width: 100%;
  margin-right: 8px;
  margin-left: 20px;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 6px;
  appearance: none;
  outline: 0;
  cursor: pointer;

  ${mq.gtsm`
    margin-right: -8px;
  `}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: ${(props) => (props.open ? 1 : 0)};
  pointer-events: ${(props) => (props.open ? 'auto' : 'none')};
  transition: opacity 150ms linear;

  ${(props) =>
    props.hasBanner &&
    css`
      top: ${props.bannerHeight}px;
    `
  }
`;

export const SearchInput = styled.input`
  padding: 8px 4px;
  width: 100%;
  color: ${colors.N900};
  font-family: ${props => fonts(props.theme.locale).SANS_CONDENSED};
  font-size: 20px;
  font-weight: ${weights.BOLD};
  line-height: 120%;
  border: 0;
  outline: 0;

  &::placeholder {
    color: ${colors.N100};
  }

  ${mq.gtsm`
    font-size: 32px;
    line-height: 100%;
  `}
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 16px 0;
  background-color: ${colors.N0};
  transform: translateY(${(props) => (props.open ? '0' : '-100%')});
  transition: transform 200ms ease;

  & > svg {
    position: absolute;
    top: 100%;
    fill: ${colors.N0};
    transform: scale(1, -1) translateY(1px);
  }

  ${mq.gtsm`
    padding: 32px 0;
  `}
`;

function GlobalSearch({ bannerHeight, hasBanner }) {
  const intl = useIntl();

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const searchRef = useRef(null);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setSearch(value);
  };

  const handleClose = () => {
    setOpen(false);
    setSearch('');
  };

  const handleKeyUp = (evt) => {
    if (evt.keyCode === 27) {
      handleClose();
    }
  };

  const handleOpen = () => {
    setOpen(true);

    if (get(searchRef, 'current')) {
      searchRef.current.focus();
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setOpen(false);
    const searchPath = '/search';
    const path = `${searchPath}?${qs.stringify({ query: search })}`;
    if (window.location.pathname === searchPath) {
      window.location.href = path;
    } else {
      navigate(path);
    }
    setSearch('');
  };

  return (
    <>
      <IconButton onClick={handleOpen} aria-label="Search Button">
        <Search />
      </IconButton>
      <Overlay bannerHeight={bannerHeight} hasBanner={hasBanner} open={open}>
        <Wrapper open={open}>
          <Container centered>
            <Inner>
              <InputWrapper>
                <Search size={24} />
                <Form onSubmit={handleSubmit}>
                  <SearchInput
                    aria-label="Search"
                    ref={searchRef}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    placeholder={intl.formatMessage({ id: 'globalSearch.placeholder' })}
                    value={search}
                  />
                </Form>
              </InputWrapper>
              <IconButton onClick={handleClose} aria-label="Close Button">
                <Close size={20} />
              </IconButton>
            </Inner>
          </Container>
          <RoughOne />
        </Wrapper>
      </Overlay>
    </>
  );
}

export default GlobalSearch;
