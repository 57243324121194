import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import get from 'lodash/get';

import colors from '@utils/colors';
import { H_PADDING_MOBILE } from '@utils/space';
import { ChevronDown } from '@components/Icon';
import { Heading } from '@components/type';
import SubMenu from './SubMenu';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px ${H_PADDING_MOBILE};
  background-color: ${colors.N0};
`;

const Toggle = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;

  & svg {
    vertical-align: middle;
  }
`;

const Wrapper = styled(motion.div)`
  &:not(:last-child) {
    border-bottom: 3px solid ${colors.B100};
  }
`;

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

function MenuItem({ closeMenu, menuIsOpen, childRoutes, route }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (menuIsOpen) {
      setIsOpen(false);
    }
  }, [menuIsOpen]);

  return (
    <Wrapper variants={variants}>
      <Header>
        <Link to={get(route, 'slug.current')} onClick={closeMenu}>
          <Heading level={400}>{get(route, 'title')}</Heading>
        </Link>
        {childRoutes.length > 0 && (
          <Toggle
            animate={isOpen ? 'open' : 'closed'}
            variants={{
              open: {
                rotate: 180,
              },
              closed: {
                rotate: 0,
              },
            }}
            onClick={toggleIsOpen}
          >
            <ChevronDown />
          </Toggle>
        )}
      </Header>
      {childRoutes.length > 0 && (
        <AnimatePresence initial={false}>
          {isOpen && <SubMenu closeMenu={closeMenu} isOpen={isOpen} items={childRoutes} />}
        </AnimatePresence>
      )}
    </Wrapper>
  );
}

MenuItem.propTypes = {
  items: PropTypes.array,
  menuIsOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default MenuItem;
