import styled from 'styled-components';

import { mq } from '@utils/responsive';

export const SmallLogo = styled.div`
  width: 42px;

  svg {
    width: 100%;
    height: auto;
  }

  ${mq.gtmd`
    width: 76px;
  `}
`;