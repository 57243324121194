import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby-plugin-react-intl';

import colors from '@utils/colors';
import { H_PADDING_MOBILE } from '@utils/space';
import GlobalSearch from '@components/GlobalSearch';
import Wordmark from '@components/Wordmark';
import MenuToggle from './MenuToggle';

const NavButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  border-radius: 6px;

  & > svg {
    vertical-align: middle;
  }
`;

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9998;
  padding: 8px calc(${H_PADDING_MOBILE} - 6px);
  background-color: transparent;
  transition: background-color 300ms cubic-bezier(0.215, 0.61, 0.355, 1);

  ${(props) =>
    props.hasScrolled &&
    css`
      background-color: ${colors.N0};
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    `}

  ${(props) =>
    props.hasBanner &&
    css`
      top: ${props.bannerHeight}px;
    `}

  ${(props) =>
    props.isOpen &&
    !props.hasScrolled &&
    css`
      background-color: transparent;
    `}

  & > a > svg {
    height: 60px;
    width: auto;
    vertical-align: middle;
  }
`;

function NavBar({
  bannerHeight,
  closeMenu,
  hasBanner,
  hasScrolled,
  isOpen,
  toggleIsOpen,
}) {
  return (
    <Wrapper
      bannerHeight={bannerHeight}
      hasBanner={hasBanner}
      hasScrolled={hasScrolled}
      isOpen={isOpen}
    >
      <NavButton>
        <MenuToggle open={isOpen} toggle={toggleIsOpen} />
      </NavButton>
      <Link to="/" onClick={closeMenu}>
        <Wordmark collapsed />
      </Link>
      <GlobalSearch bannerHeight={bannerHeight} hasBanner={hasBanner} />
    </Wrapper>
  );
}

NavBar.propTypes = {
  hasScrolled: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleIsOpen: PropTypes.func.isRequired,
};

export default NavBar;
