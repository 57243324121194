import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';
import { H_PADDING_MOBILE } from '@utils/space';
import UnstyledLink from '@components/Link';
import { Body as UnstyledBody } from '@components/type';

export const Wrapper = styled.footer`
  font-family: ${props => fonts(props.theme.locale).SANS};
  padding: 0 ${H_PADDING_MOBILE};

  ${mq.gtsm`
    max-width: 1060px;
    margin: 0 auto;
  `}
`;

export const Body = styled.div`
  margin-bottom: 1rem;

  ${mq.gtmd`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 3rem;
  `}
`;

export const Chat = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0 16px;

  ${mq.gtsm`
    margin: 48px 0 32px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ChatLink = styled.a`
  display: inline-block;
  margin-top: 8px;
  color: ${colors.B500};
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 14px;
  font-weight: ${weights.BOLD};
  text-decoration: underline;
  cursor: pointer;

  ${mq.gtsm`
    margin-top: 16px;
    font-size: 16px;
  `}
`;

export const Description = styled.div`
  margin-bottom: 2rem;

  ${mq.gtmd`
    width: 50%;
  `}
`;

export const About = styled(UnstyledBody)`
  margin-top: 1rem;
  color: ${colors.N500};
`;

export const Nav = styled.div`
  width: 100%;

  ${mq.gtmd`
    width: 40%;
    margin-left: auto;
  `}

  ${mq.gtlg`
    width: 35%;
    margin-left: auto;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 64px;

  &:only-child {
    width: 72px;
    height: 72px;
  }

  ${mq.gtsm`
    width: 96px;
    height: 96px;

    &:only-child {
      width: 120px;
      height: 120px;
    }
  `}
`;

export const ImagesWrapper = styled.div`
  display: flex;
  margin-right: 32px;

  & > *:not(:first-child) {
    margin-top: 32px;
    margin-left: -32px;
  }
`;

export const LinkList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
`;

export const Link = styled(UnstyledLink)`
  width: 50%;
  color: ${colors.N500};

  &:hover {
    color: ${colors.N900};
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  flex: 1 0 100%;
`;

export const SocialList = styled.div`
  display: flex;
`;

export const WavyBorder = styled.div`
  margin-bottom: 1rem;

  svg {
    width: 100%;
    height: auto;
  }

  ${mq.gtmd`
    margin-bottom: 2rem;
  `}
`;

export const Enquire = styled.div`
  a {
    z-index: 9999;
    position: fixed;
    background: ${(props) => getEnquireBackground(props.colorTheme)};
    bottom: 0;
    width: 100vw;
    left: 0;
    text-align: center;
    height: 64px;


    ${mq.btmlmd`
        right: 30px !important;
    `}

    ${mq.gtmd`
      margin-bottom: 1rem;
      bottom: 30px;
      height: 128px;
      width: 128px;
      border-radius: 50%;
      left: inherit;
      right: calc(50% - 620px);
    `}

    p {
      line-height: 64px;
      margin: 0;
      color: ${colors.N0};
      letter-spacing: 3px;

      ${mq.gtmd`
        line-height: 128px;
      `}
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-bottom: 24px;
  font-size: 0.75rem;
  color: ${colors.N500};

  ${mq.gtmd`
    align-items: center;
    flex-direction: row;
    padding-bottom: 64px;
    font-size: 0.875rem;
  `}
`;

export const FooterNav = styled.ul`
  display: flex;
  margin-bottom: 1rem;
`;

export const FooterNavItem = styled.li`
  display: flex;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  a {
    color: ${colors.N500};

    &:hover {
      color: ${colors.N900};
    }
  }

  ${mq.gtsm`
  `}
`;

function getEnquireBackground(colorTheme) {
  switch (colorTheme) {
    case 'yellow':
      return colors.Y500;
    case 'green':
      return colors.G500;
    case 'orange':
      return colors.O500;
    case 'purple':
      return colors.P500;
    default:
      return colors.B500;
  }
}
